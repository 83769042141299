html,
body {
  margin: 0 auto;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
  height: 100%;
  max-width: 600px;
}

/* Header 영역 */
.header_qna {
  height: 110px;
  width: 100%;
  position: fixed;
  top: 0;
  background: white;
  z-index: 2;
  max-width: 600px;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  height: 40px;
  padding-bottom: 20px;
  line-height: 40px;
}

/* Main 영역 */
.main_qna_write {
  width: 100%;
  padding-top: 120px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  height: 100vh;
  overflow: auto;
  z-index: 1;
  padding-bottom: 100px;
  overflow-x: hidden;
}

.dropdown-select__control.dropdown-select__control--is-focused {
  font-size: 16px !important;
}

.main_qna_list {
  width: 100%;
  padding-top: 120px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  height: 100vh;
  overflow: auto;
  z-index: 1;
  padding-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
}

.tabs li {
  width: calc(100% - 50%);
  font-size: 15px;
  font-weight: 600;
  height: 47px;
  line-height: 47px;
  text-align: center;
  display: inline-block;
  color: #8e8e93;
  border-bottom: 3px solid #f6f6f6;
}
.tabs li.active {
  color: #ff6700;
  border-bottom-color: #ff6700;
}

.qna_input_content {
  width: calc(100% - 20px - 2px);
  min-height: 100px;
  max-height: 200px;
  margin-top: 10px;
  margin: 0;
  border: 1px solid #aeaeb2;
  border-radius: 5px;
  text-align: start;
  padding: 10px;
  resize: vertical;
  font-size: 15px;
  outline: none;
}

.qna_input_content:focus {
  border: 1px solid #ff6700;
}

.input_form {
  height: 44px;
  display: flex;
  border-bottom: 1px solid #c7c7cc;
}

.input_name {
  flex: 5;
  border: none;
  outline: none;
  caret-color: #ff6700;
  color: #1a1a1a;
  font-size: 15px;
}

.input_phone {
  flex: 5;
  border: none;
  outline: none;
  caret-color: #ff6700;
  color: #1a1a1a;
  font-size: 15px;
}

select {
  flex: 5;
  border: none;
  outline: none;
  color: #1a1a1a;
  font-size: 15px;
}

.qna_type {
  flex: 5;
  height: 44px;
  border: none;
  box-shadow: none;
  outline: none;
  color: #1a1a1a;
  font-size: 15px;
}

.input_title {
  flex: 1;
  font-size: 15px;
  line-height: 44px;
}

.space_v20 {
  height: 20px;
}

.is_answered {
  color: #ff6700;
}

.summary_container {
  width: 100%;
  font-size: 15px;
}

.MuiAccordionSummary-content {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.question_summary {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.textClass {
  word-wrap: break-word;
}

.empty_qna {
  width: 100%;
  height: 300px;
  line-height: 300px;
  text-align: center;
  color: #8e8e93;
}

.MuiCircularProgress-colorPrimary {
  color: #ff6700 !important;
}

.region {
  width: 100%;
}

.MuiCollapse-wrapper {
  width: 100%;
}

.MuiAccordionDetails-root {
  width: calc(100% - 52px);
}

.detail_container {
  width: 100%;
  font-size: 15px;
  display: block;
  padding: 0;
}

.question_detail {
  width: 100%;
  flex-shrink: 0;
  color: #8e8e93;
  font-size: 15px;
  background-color: #f6f6f6;
  padding: 20px 10px;
}

.answer_detail {
  color: black;
  font-size: 15px;
  padding: 10px 10px;
}

/* Footer 영역 */
footer {
  width: calc(100% - 30px);
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  bottom: 0px;
  position: fixed;
  padding-bottom: 20px;
  background: white;
  max-width: 600px;
  z-index: 2;
}

.button {
  width: 100%;
  height: 50px;
  background-color: #1a1a1a;
  color: #ffffff;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  border-radius: 10px;
  border: 0;
}

.button:enabled {
  background-color: #1a1a1a;
  color: #ffffff;
}

.button:disabled {
  background-color: #f6f6f6;
  color: #c7c7c7;
}

.image {
  flex: 1;
  resize: contain;
  width: 400px;
  height: 400px;
  aspect-ratio: 1/1;
}

.center-cropped {
  object-fit: cover;
  object-position: center; /* Center the image within the element */
  height: 200px;
  width: 200px;
}

.img {
  width: auto;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
/* 이미지 클릭 시, 밝기 조절 */
.img:hover {
  opacity: 0.8;
}

.modal {
  display: none; /* 모달창 숨겨 놓기 */
  position: fixed;
  z-index: 100; /* 모달창을 제일 앞에 두기 */
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* 스크롤 허용 auto */
  cursor: pointer; /* 마우스 손가락모양 */
  background-color: rgba(0, 0, 0, 0.8);
}
/* 모달창 이미지 */
.modal_content {
  margin: auto;
  display: block;
  width: 50%;
  height: auto;
  max-width: 600px;
  border-radius: 10px;
  animation-name: zoom;
  animation-duration: 0.8s;
}
/* 모달창 애니메이션 추가 */
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
/* 닫기 버튼 꾸미기 */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.input-file-button {
  width: 100px;
  height: 40px;
  text-align: center;
  background-color: #ff6600;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  justify-content: center;
}
