html, body {
  margin: 0 auto;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif;
  height: 100%;
  max-width: 600px;
}

/* Header 영역 */
header {
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  background: white;
  z-index: 2;
  max-width: 600px;
}

/* Main 영역 */
main {
  width: 100%;
  padding-top: 60px;
  height: calc(100vh + 30px);
  padding-bottom: 100px;
  overflow: auto;
  z-index: 1;
}

.invalid_order {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
  line-height: 20px;
  text-align: center;
}

.title {
  width: 100%;
  text-align:center;
  font-size: 17px;
  font-weight: 600;
  height: 40px;
  padding-bottom: 20px;
  line-height: 40px;
}

.saved_info {
  padding-bottom: 10px;
  padding-top: 5px;
  font-size: 15px;
  color: #ff6700;
  padding-left: 20px;
  padding-right: 20px;
}

.place_info {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
  line-height: 20px;
}

.place_info_text {
  font-size: 15px;
  display: inline-block; 
}

.receiver_address {
  font-size: 15px;
  font-weight: 600;
  display: inline-block; 
  padding-top: 15px;
  padding-bottom: 10px;
  height: 20px;
  width: calc(100% - 40px);
  padding-left: 20px;
  padding-right: 20px;
  line-height: 30px;
}

.receiver_address_detail {
  font-size: 15px;
  font-weight: 600;
  display: inline-block; 
  height: 20px;
  padding-left: 68px;
  line-height: 20px;
}

.place_info_text_bold {
  font-size: 15px;
  font-weight: 600;
  display: inline-block; 
}

.delivery_location_container {
  width: calc(100%-40px);
  padding-left: 20px;
  padding-right: 20px;
}

label {
  height: 44px;
  display:flex;
  margin: auto 0;
  align-items: center;
}

.label_font {
  font-size: 15px;
  height: 44px;
  font-weight: normal;
  padding-left: 3px;
  align-items: center;
  margin: auto 0;
  align-content: center;
}

.entrance_container {
  width: calc(100%-40px);
  padding-left: 20px;
  padding-right: 20px;
}

.entrance_title {
  width: calc(100%-40px);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  height: 44px;
  line-height: 40px;
  font-size: 15px;
  font-weight: 600;
}

.entrance_title_line {
  width: 100%;
  height: 0.5px;
  background-color: #C7C7CC;
  margin-bottom: 10px;
}

.notice_container {
  width: calc(100%-40px);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.notice_title {
  height: 30px;
  display: flex;
  align-items: center;
}

.notice_title_font {
  height: 30px;
  line-height: 30px;
  margin-left: 3px;
  font-size: 15px;
  color: #ff6700;
  font-weight: 600;
}

.notice_desc_font {
  font-size: 15px;
  color: #ff6700;
}

.notice_title img {
  width: 22px;
  height: 22px;
}

.notice_title div {
  margin-left: 5px;
}

/* Footer 영역 */
.cp_footer {
  width: calc(100% - 30px);
  height: 140px;
  padding-left: 15px;
  padding-right: 15px;
	bottom: 0px;
	position: fixed;
  padding-bottom: 20px;
  background: white;
  max-width: 600px;
  z-index: 2;
}

.button {
  width: 100%;
  height: 50px;
  background-color: #1A1A1A;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  border-radius: 10px;
  border: 0;
}

.button:enabled {
  background-color: #1A1A1A;
  color: #FFFFFF;
}

.button:disabled {
  background-color: #F6F6F6;
  color: #C7C7C7;
}

/* 기타 영역(라디오버튼) */
input[type=radio]{
  border: 1px solid #C7C7CC;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  display: none;
}

input[type=radio] + label>img{
  height: 22px;
  width: 22px;
  display: inline-block;
  content: url("https://cdn.0br1.io/img/icons/circle_72x72.png");
  margin-right: 5px;
}

input[type=radio]:checked + label>img {
  height: 22px;
  width: 22px;
  display: inline-block;
  content: url("https://cdn.0br1.io/img/icons/circle_checked_72x72.png");
  margin-right: 5px;
}

input[type=radio]:hover + label>img{
  transform: scale(1.1);
  cursor: pointer;
}

/* 기타 영역(체크박스) */
input[type=checkbox]{
  border: 1px solid #C7C7CC;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: none;
}

input[type=checkbox] + label>img{
  height: 30px;
  width: 30px;
  display: inline-block;
  content: url("https://cdn.0br1.io/img/icons/square_uncheck_72x72.png");
  margin-right: 5px;
  text-overflow: ellipsis;
}

input[type=checkbox]:checked + label>img {
  height: 30px;
  width: 30px;
  display: inline-block;
  content: url("https://cdn.0br1.io/img/icons/square_check_72x72.png");
  margin-right: 5px;
}

input[type=checkbox]:hover + label>img{
  transform: scale(1.1);
  cursor: pointer;
}

.input_etc_place {
  width: 100%;
  height: 44px;
  display: block;
  font-size: 15px;
  box-sizing: border-box;
  padding-left: 6px;
    padding-right: 6px;
    border: solid 1px #E5E5EA;
    border-radius: 5px;
}

.input_etc_place:focus {
  outline: none;
  border: solid 1px #ff6700;
}

.input_box {
  width: 100%;
  height: 44px;
  display: block;
  font-size: 15px;
  box-sizing: border-box;
  padding-left: 6px;
  padding-right: 6px;
  border: solid 1px #E5E5EA;
  border-radius: 5px;
  margin-left: 3px;
  color: black;
}

.input_box::placeholder {
  color: #8E8E93;
  font-weight: normal;
}

.input_box:focus-visible {
  outline: none;
  border: solid 1px #ff6700;
}

.spinner_container {
  text-align: center;
}

.MuiCircularProgress-colorPrimary {
  color: #FF6700 !important;
}

.check_agreement_privacy_container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.img_question {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  content: url("https://cdn.0br1.io/img/icons/question_circle_90x90.png");
}

.img_question:hover {
  cursor: pointer;
}

.save_button_disabled {
  width: 100%;
  height: 50px;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  border-radius: 10px;
  border: 0;
  background-color: #F6F6F6;
  color: #C7C7C7;
}

.save_button_enabled {
  width: 100%;
  height: 50px;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  border-radius: 10px;
  border: 0;
  background-color: #1A1A1A;
  color: #FFF;
}